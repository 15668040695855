import React, {useState, Suspense, lazy } from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {Container} from '@material-ui/core';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles'

const Gallery = lazy(() => import('./components/Gallery'));
const MemeGene = lazy(() => import('./components/MemeGene'));
const Shop = lazy(() => import('./components/Shop'));
const Auth  = lazy(() => import('./components/Auth'));
const AuthenticateOnChain  = lazy(() => import('./components/AuthenticateOnChain'));
const Navbar  = lazy(() => import('./components/Navbar'));
const Dashboard  = lazy(() => import('./components/Dashboard'));
const TOS  = lazy(() => import('./components/TOS'));
const DiscordRedirect  = lazy(() => import('./components/common/redirect'));

const App = () => {
    const [toggleDark, settoggleDark] = useState(true);
    const theme = createTheme({  
        palette: {
            primary: {
              light: toggleDark ? '#c1f' : '#c1f',
              main: toggleDark ? '#08a' : '#08a',
              dark: toggleDark ? '#114' : '#114',      
              contrastText: toggleDark ? '#104' : '#104'
          }
        }
    })
    return (
        <MuiThemeProvider theme={theme}>
            {toggleDark ? (<style>{`
                @media (max-width: 899px) {
                    body {
                        background-image: url("/images/backgrounds/mobile/dark/dark-${Math.floor(Math.random() * 28)}.jpg"), url("/images/backgrounds/mobile/texture/texture-${Math.floor(Math.random() * 28)}.jpg");
                        background-blend-mode: color-dodge;
                    }
                }
                @media (min-width: 900px) {
                    body {
                        background-image: url("/images/backgrounds/desktop/dark/dark-${Math.floor(Math.random() * 28)}.jpg"), url("/images/backgrounds/desktop/texture/texture-${Math.floor(Math.random() * 28)}.jpg");
                        background-blend-mode: color-dodge;
                    }
                }
                    `}</style>) : (<style>{`
                @media (max-width: 899px) {
                    body {
                        background-image: url("/images/backgrounds/mobile/light/light-${Math.floor(Math.random() * 28)}.jpg"), url("/images/backgrounds/mobile/texture/texture-${Math.floor(Math.random() * 28)}.jpg");
                        background-blend-mode: color-dodge;
                    }
                }
                @media (min-width: 900px) {
                    body {
                        background-image: url("/images/backgrounds/desktop/light/light-${Math.floor(Math.random() * 28)}.jpg"), url("/images/backgrounds/desktop/texture/texture-${Math.floor(Math.random() * 28)}.jpg");
                        background-blend-mode: color-dodge;
                    }
                }
            `}</style>)}
                
            <Suspense fallback={<div>Loading...</div>}>
                <BrowserRouter>
                    <Navbar toggleDark={toggleDark} settoggleDark={settoggleDark} />
                    <Container>
                        <Routes>
                            <Route path="/shop" element={<Shop />} />
                            <Route path="/auth" element={<Auth/>} />
                            <Route path="/blk-auth/*" element={<AuthenticateOnChain/>} />
                            <Route path="/terms-of-service" element={<TOS/>} />
                            <Route path="/auth/discord" element={<DiscordRedirect to="https://discord.com/api/oauth2/authorize?client_id=579940300131139627&redirect_uri=http%3A%2F%2Fapi.blackcoin.nl%2Fsocial%2Fdiscord-oauth2&response_type=code&scope=identify%20email" />} />
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/gallery" element={<Gallery/>} />
                            <Route path="/*" element={<><MemeGene /><Gallery /></>} />
                        </Routes>
                    </Container>
                </BrowserRouter>
            </Suspense>
        </MuiThemeProvider>
    )
}

export default App