import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { cartReducer } from './reducers/cart';
import { authReducer } from './reducers/auth';
import { getProductsReducer, getProductDetailsReducer } from './reducers/product';

export const reducer = combineReducers({
    cart: cartReducer,
    auth: authReducer,
    products: getProductsReducer,
    productDetails: getProductDetailsReducer,
});

const middleware = [thunk];

const store = createStore(
    reducer,
    composeWithDevTools(applyMiddleware(...middleware))
);
export default store;