import {ADD_TO_CART, PAY_FOR_CART, REMOVE_FROM_CART} from '../constants/CART';

export const cartReducer = (state = { cart: [] }, action) => {
    switch(action.type) {
        case ADD_TO_CART:
            const item = action.payload;
            let cartAddVar = JSON.parse(localStorage.getItem('cart'))
            cartAddVar
                ? cartAddVar = {...cartAddVar, ...item}
                : cartAddVar = {...item};
            console.log("reducers/cart.js", cartAddVar)
            
            localStorage.setItem('cart', JSON.stringify(cartAddVar));

            return {cart: cartAddVar};

        case PAY_FOR_CART:
            let existing = JSON.parse(localStorage.getItem('cart'))
            console.log("reducers/cart.js", existing)
            

            
            localStorage.removeItem('cart');
            return {cart: {}};

        case REMOVE_FROM_CART:
            let cartRmVar = JSON.parse(localStorage.getItem('cart'));
            cartRmVar
                ? cartRmVar = {...cartRmVar.cart.filter((x) => x.product !== action.payload)}
                : cartRmVar = {};
            console.log("reducers/cart.js", cartRmVar)
            
            localStorage.setItem('cart', JSON.stringify(cartRmVar));

            return {
                cart: cartRmVar
            }
        default:
            return state;
    }
}