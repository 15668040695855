const config = {
    googleClientID: "80602624960-3c2f9d3blfr57c7j82di63lkcvv48pgg.apps.googleusercontent.com",
    giphy_api: process.env.REACT_APP_GIPHY_API,
    crypto_id_api: process.env.REACT_APP_CRYPTO_ID_API
};
export default config;

const express = {
    shop: process.env.REACT_APP_EXPRESS_SHOP,
    auth: process.env.REACT_APP_EXPRESS_AUTH,
    social: process.env.REACT_APP_EXPRESS_SOCIAL,
    post: process.env.REACT_APP_EXPRESS_POST,
}
export {express};

