import React from 'react';
import {render} from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import {Provider} from 'react-redux';
import store from './redux/store';

import './index.css';

import App from './App';

document.addEventListener("DOMContentLoaded", () => {
  render(
    <CookiesProvider>
      <Provider store={store}>
          <App />
      </Provider>
    </CookiesProvider>,
    document.body.appendChild(document.createElement("div"))
  );
});