import * as actionTypes from '../constants/PRODUCT';

export const getProductsReducer = (state = { products: [] }, action) => {
    switch (action.type) {
        case actionTypes.GET_PRODUCTS_REQUEST:
            return {
                loading: true,
                products: [],
            };
        case actionTypes.GET_PRODUCTS_SUCCESS:
            return {
                loading: false,
                products: action.payload,
            };
        case actionTypes.GET_PRODUCTS_FAILURE:
            return {
                loading: false,
                products: action.payload,
            };
        case actionTypes.CREATE:
            return [
                ...state.products.products, action.payload
            ];
        case actionTypes.UPDATE:
        case actionTypes.LIKE:
        case actionTypes.DISLIKE:
            const theGoods = state.products.map(
                (product) => (
                    product._id === action.payload._id
                        ? action.payload
                        : product
                )
            );
            return {products: theGoods}
        default:
            return state;
    }
};

export const getProductDetailsReducer = (state = { product: {} }, action) => {
    switch (action.type) {
        case actionTypes.GET_PRODUCT_DETAILS_REQUEST:
            return {
                loading: true,
                product: [],
            };
        case actionTypes.GET_PRODUCT_DETAILS_SUCCESS:
            return {
                loading: false,
                product: action.payload,
            };
        case actionTypes.GET_PRODUCT_DETAILS_FAILURE:
            return {
                loading: false,
                product: action.payload,
            };
        case actionTypes.GET_PRODUCT_DETAILS_RESET:
            return {
                product: {},
            };
        default:
            return state;
    }
};