import axios from 'axios';
import {express} from '../config';

export const https = (target) => {
    const api = axios.create({
        baseURL: `${target}`,
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": `${window.location.origin.toString()}`
        }
    });
    api.interceptors.request.use((req) => {
      if(localStorage.getItem('profile')) {
          req.headers.authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`
      }
      return req;
  })
  return api;
};


export const authApi = https(express.auth)

export const mongoApi = https(express.shop)
