import { AUTH, LOGOUT } from '../constants/AUTH';
import {checkGoogleId} from '../../axios/auth.js'

export const authReducer = async (state = {profile: {}}, action) => {
    switch (action.type) {
        case AUTH:
            let profile = JSON.parse(localStorage.getItem('profile'))
            console.log(action.data.token)
            profile
                ? profile = {...profile, ...action.data.result, token: action.data.token}
                : profile = {...action.data.result, token: action.data.token};
            console.log("reducers/auth.js", profile)
            
            let preExisting = {};
            if (profile.googleId && !profile.blackcoinAddress) {
                preExisting = await checkGoogleId({body: profile.googleId})
                console.log(preExisting.data)
                if (preExisting.data.existing.id) {
                    profile = {name:preExisting.data.existing.name, email:preExisting.data.existing.email, blackcoinAddress:preExisting.data.existing.blackcoinAddress, id:preExisting.data.existing.id}
                };
            } else if (profile.googleId && profile.blackcoinAddress) {
                alert("FIX: Update Address")
            };

            if (profile.id) {
                localStorage.setItem('profile', JSON.stringify(profile));
                return {profile: profile}
            } else return alert("fail ID check; Register with a Blackcoin Address");
        case LOGOUT:
            localStorage.clear();
            return {profile: null};
        default:
            return state
    }
}